import { render, staticRenderFns } from "./MSPTS311P.vue?vue&type=template&id=5bef8c93&scoped=true&"
import script from "./MSPTS311P.vue?vue&type=script&lang=js&"
export * from "./MSPTS311P.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bef8c93",
  null
  
)

export default component.exports