/*
 * 업무구분: FC조회
 * 화 면 명: MSPTS316P
 * 화면설명: FC조회
 * 작 성 일: 2023.03.16
 * 작 성 자: 김경태
 * 수 정 일: 2024-10-14 정수진 사유 : ASR241000281_[사랑On신탁] 권유대행FC 조회 불가건 수정
 */
<template>
  <mo-modal class="fts-modal large" ref="modal" title="FC조회">
    <div class="wrap-modalcontents">
      <div class="input-top">
        <div class="column w100p gap12">
          <div class="row w100p">
            <div class="left">
              <div class="wrap-input row mr20">
                <label> 사원번호 </label>
                <mo-decimal-field v-model="fcNo" clearable class="w130" numeric mask="########" maxlength="8" />
              </div>
              <div class="wrap-input row">
                <label> 성명 </label>
                <mo-text-field v-model="fcNm" clearable class="w130" />
              </div>
            </div>
            <div class="right">
              <div class="wrap-button row">
                <mo-button @click="fn_Clear()"> 초기화 </mo-button>
                <mo-button @click="fn_Search()" primary> 조회 </mo-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrap-table mt16 h-scroll-250">
        <table class="table row-type aC">
          <thead>
            <tr>
              <th class="w40"> </th>
              <th> 사원번호 </th>
              <th> 성명 </th>
              <th> 지점 </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(data, idx) in tpup95290" >
              <tr :key="idx" @click="fn_RowSelected(idx.toString())">
              <td><mo-radio :id="idx" v-model='lv_SelectedItem' :value="idx.toString()" @click="fn_RowSelected(idx.toString())"/></td>
              <td>{{ data.fcEmno}}</td>
              <td>{{ data.fcNm}}</td>
              <td>{{ data.plzaNm}}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
        <mo-button primary size="large" @click="fn_Confirm">확인</mo-button>
      </div>
    </template>
  </mo-modal>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import moment from 'moment'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPTS316P',
  // 현재 화면 ID
  screenId: 'MSPTS316P',
  // 컴포넌트 선언
  components: {
    
  },
  // 화면명
  props: {
    pPage: String,      // 부모 페이지명
    pGdC: String,       // 신탁상품
    pTrstTypeC: String, // 신탁유형
    pGdTypDtlC: String, // 상품유형
    pProcTc: String,
    popupObj: {type:Object, default:null},
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      userId: '',
      userNm: '',
      searchKeyword: '', // 고객명
      trstTypC: '',
      gdTypDtlC: '',
      lv_Params:{},
      lv_SelectedItem: '',
      lv_ReturnVal: {}, // 리턴값
      lv_ReturnStr: [], // 베이스 화면으로 리턴할 조회조건 텍스
      fcNm: '',
      fcNo: '',
      fcRcno: '',
      fcRcno2: '', //password 뒷자리 필드 초기화를 위한 임시 셋팅
      tpup95290: [],
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(),
      invPosYn:''
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    modal() {
      return this.$refs.modal
    },
  },
  
  /** watch 정의 영역 */
  watch: {},

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPTS316P')

  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_Init () {
      this.modal.open()
    },
    fn_Open(param){
      this.modal.open()
      this.fn_Clear()
      this.invPosYn = param.invPosYn
    },
    fn_Close(){
      this.modal.close()
    },
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () { 
      this.$set(this, 'fcNo', '')      
      this.$set(this, 'fcNm', '')      
      //this.$set(this, 'fcRcno', '')  
      //this.$set(this, 'fcRcno2', '') 
      this.tpup95290 = []
    },
    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 조회 후 팝업 닫음
     ******************************************************************************/
    fn_Search () {
      const lv_Vm = this // (현) Vue객체모델 인스턴스

      if((this.fcNo.length + this.fcNm.length) < 1){
        let t_popupObj = {
          confirm: false,
          content: '사원번호, 사원명 둘 중 하나는 2글자 이상 입력해 주세요.'
        }
        this.$emit('ts-alert-popup', 0, t_popupObj)
        return
      }
      // if((this.fcNo.length > 0 && this.fcNo.length) < 2){
      //   let t_popupObj = {
      //     confirm: false,
      //     content: '사원번호를 2글자 이상 입력해 주세요.'
      //   }
      //   this.$emit('ts-alert-popup', 0, t_popupObj)
      //   return
      // }else if((this.fcNm.length > 0 && this.fcNm.length) < 2){
      //   let t_popupObj = {
      //     confirm: false,
      //     content: '사원명을 2글자 이상 입력해 주세요.'
      //   }
      //   this.$emit('ts-alert-popup', 0, t_popupObj)
      //   return
      // }
      let pParams = {
        //rcno:this.fcRcno,
        rcno:'',
        fcEmno:this.fcNo,
        fcNm:this.fcNm
      }
      console.log(pParams);
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS92S1'
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.params = pParams
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600225_S'

      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SearchResult)
    },

    /******************************************************************************
     * Function명 : fn_SearchResult
     * 설명       : 조회 콜백
     ******************************************************************************/
    fn_SearchResult (res) {
      this.tpup95290 = res.data.tpup95290
    },
    /******************************************************************************
     * Function명 : fn_Confirm
     * 설명       : 확인
     ******************************************************************************/
    fn_Confirm () {
      if(TSCommUtil.gfn_isNull(this.rowSelect)){
        this.fn_Close()
      }else{
        if(this.invPosYn === 'Y' && this.rowSelect.invPosYn === 'N'){
          TSCommUtil.gfn_validate(this, '해당 직원은 신탁상품 권유자격이 불충분하여 권유자로 입력할 수 없습니다.')
          this.errValCssearchRsn = true
          return
        }else{
          this.$emit('ts-popup316-callback', this.rowSelect)
        }
        
        this.fn_Close()
      }
    },

    /***************************************************************************************
    * Function명 : fn_comEvent
     * 설명       : 한글 입력 방지(숫자만 입력 체크) 
    *****************************************************************************************/
      async fn_comEvent(event, type) {
        if ( type == 1) { //acno
          let acno = this.fcNo.replace(/[^0-9]/g, '')
          this.fcNo = acno
        } else { //fcRcno
          let fcRcno = this.fcRcno.replace(/[^0-9]/g, '')
          this.fcRcno = fcRcno
        }

      },
    /******************************************************************************
     * Function명 : fn_RowSelected
     * 설명       : 조회내역 선택
     ******************************************************************************/
    fn_RowSelected(idx){
      this.lv_SelectedItem = idx

      let findIdx = this.tpup95290.findIndex(obj => obj.chnlCustId === this.lv_SelectedItem)
      if (findIdx !== -1) {
        Object.assign(this.lv_SelectedItem, this.tpup95290[findIdx])
      }
      this.rowSelect = this.tpup95290[idx]

    }
  },
  
}
</script>
